import { Button, SelectOptionType, addToast, Select } from '@octano/global-ui';
import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';

import { HTTP_STATUS_CODE } from '../../../../api/request';
import { createPetition } from '../../../../api/requests/petitions';
import { useBase } from '../../../../components/base/BaseProvider';
import { useFetch } from '../../../../components/contexts/FetchContext';
import DisplayError from '../../../../components/info/DisplayError';
import Loading from '../../../../components/info/Loading';
import { TranslationsKeys } from '../../../../locales/translations';
import {
  PaginationQuery,
  PaginationRequestType,
} from '../../../../types/paginationRequestType';
import {
  CreatePetitionData,
  PetitionData,
  PetitionInf,
  PetitionTypes,
} from '../../../../types/petitions';
import PetitionFormDefault from '../../parts/PetitionFormDefault';
import PetitionFormReplaceCourseGrade from './PetitionFormReplaceCourseGrade';
import PetitionFormReplaceEvaluationGrade from './PetitionFormReplaceEvaluationGrade';

export interface CreatePetitionFormProps extends CreatePetitionData {
  close: () => void;
  error?: string;
  loading: boolean;
}
export default function CreatePetitionForm({
  types,
  cellPhone,
  email,
  replaceEvaluationGrades,
  replaceCourseGrades,
  close,
  error,
  loading,
}: CreatePetitionFormProps) {
  const { t } = useTranslation(TranslationsKeys.PETITIONS);

  const { careers } = useBase();
  const careerOptions = useMemo<SelectOptionType[]>(
    () =>
      careers.map((c) => ({
        value: c.studyPlanEnrollmentId,
        label: c.name,
      })),
    [careers],
  );

  const methods = useForm<PetitionData>({
    mode: 'onSubmit',
    defaultValues: {
      studyPlanEnrollment:
        careerOptions.length === 1 ? careerOptions[0] : undefined,
      type: undefined,
    },
  });
  const { reset, watch } = methods;

  const typeOptions = useMemo<SelectOptionType[]>(
    () => types.map((t) => ({ value: t.id, label: t.name })),
    [types],
  );

  const text = useMemo(() => {
    const prefix = 'modal';
    return {
      cancel: t(`${prefix}.newPetition.cancel`),
      save: t(`${prefix}.newPetition.save`),
      message: {
        error: t(`${prefix}.newPetition.message.error`),
        success: t(`${prefix}.newPetition.message.success`),
      },
      type: t(`${prefix}.form.type`),
      validation: {
        select: t(`${prefix}.validation.select`),
      },
    };
  }, [t]);

  const { updateQuery } = useFetch<
    PaginationQuery,
    PaginationRequestType<PetitionInf>
  >();
  const [loadingSave, setLoading] = useState(false);

  const clearForm = useCallback(() => {
    reset({
      studyPlanEnrollment: undefined,
      cellPhone,
      email,
    });
  }, [reset, cellPhone, email]);

  const onSubmit = useCallback(
    async (data: PetitionData) => {
      setLoading(true);

      const {
        cellPhone,
        email,
        doc,
        type,
        title,
        studyPlanEnrollment,
        description,
        replaceEvaluationGrade,
        studentTestGradeId,
        studentFinalGradeId,
        section,
        grade,
      } = data;

      const petitionData = {
        email,
        title,
        description,
        doc,
        phone: cellPhone,
        typeId: type.value,
        studyPlanEnrollmentId: studyPlanEnrollment.value,
        replaceEvaluationGrade: replaceEvaluationGrade?.label,
        studentTestGradeId,
        studentFinalGradeId,
        sectionId: section?.value,
        grade,
      };

      const { error, status } = await createPetition(petitionData);

      if (status === HTTP_STATUS_CODE.CREATED) {
        addToast({
          icon: 'success',
          color: 'success',
          text: text.message.success,
        });
        close();
        clearForm();
        updateQuery({ items_per_page: 10, page: 0 });
      } else if (error) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: text.message.error,
        });
      }
      setLoading(false);
    },
    [close, updateQuery, text, clearForm, setLoading],
  );

  const selectedType = watch('type');

  useEffect(() => {
    if (selectedType) {
      methods.setValue('title', '');
      methods.setValue('replaceEvaluationGrade', undefined);
      methods.setValue('studentTestGradeId', undefined);
      methods.setValue('studentFinalGradeId', undefined);
      methods.setValue('grade', undefined);
      methods.setValue('section', undefined);
    }
  }, [selectedType, methods]);

  if (error) {
    return <DisplayError insideCard loadingAction={loading} body={error} />;
  }
  if (loading) {
    return <Loading />;
  }

  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(onSubmit)}>
        <Row className="mt-4 mb-1">
          <Col md={12}>
            <Select
              label={text.type}
              name="type"
              control={methods.control}
              options={typeOptions}
              rules={{ required: text.validation.select }}
              isClearable={false}
            />
          </Col>

          {selectedType &&
            (selectedType.value === PetitionTypes.ACADEMIC ||
              selectedType.value === PetitionTypes.PAYMENT ||
              selectedType.value === PetitionTypes.OTHER) && (
              <PetitionFormDefault careerOptions={careerOptions} />
            )}

          {selectedType &&
            selectedType.value === PetitionTypes.REPLACE_EVALUATION_GRADE && (
              <PetitionFormReplaceEvaluationGrade
                careerOptions={careerOptions}
                replaceEvaluationGrades={replaceEvaluationGrades}
              />
            )}

          {selectedType &&
            selectedType.value === PetitionTypes.REPLACE_COURSE_GRADE && (
              <PetitionFormReplaceCourseGrade
                careerOptions={careerOptions}
                sectionData={replaceCourseGrades}
              />
            )}

          <Col md={6}>
            <Button
              type="button"
              className="w-100"
              outlined
              loading={loadingSave}
              onClick={() => {
                close();
                clearForm();
              }}
              text={text.cancel}
            />
          </Col>
          <Col md={6}>
            <Button
              type="submit"
              className="w-100"
              color="primary"
              loading={loadingSave}
              text={text.save}
            />
          </Col>
        </Row>
      </Form>
    </FormProvider>
  );
}
